<template>
  <div>
    <!-- Task Card Header -->
    <b-row v-if="!isLoading">
      <b-col lg="4">
        <b-card class="card" title="Daftar Siswa">
          <b-row>
            <b-col lg="1">
              <b-form-select
                v-model="pageLength"
                class="w-auto mr-1"
                :options="options"
              />
            </b-col>
            <b-col></b-col>
            <b-col lg="6">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar
                  :text="avatarText(props.row.fullName)"
                  :src="props.row.avatar"
                  variant="light-primary"
                  class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Grade -->
              <span v-else-if="props.column.field === 'grade'">
                {{ props.row.grade }}
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status).variant">
                  {{ statusVariant(props.row.status).text }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  v-if="props.formattedRow['grade'] === 0"
                  :variant="
                    props.row.status === 'unavailable' ? 'secondary' : 'primary'
                  "
                  class="d-flex align-items-center justify-content-center"
                  :disabled="props.row.status === 'unavailable'"
                  @click="fetchDetailData(props.row.id)"
                >
                  <i class="ti ti-star" />
                </b-button>
                <b-button
                  v-if="props.formattedRow['grade'] !== 0"
                  variant="warning"
                  class="d-flex align-items-center justify-content-center"
                  @click="fetchDetailData(props.row.id)"
                >
                  <i class="ti ti-pencil" />
                </b-button>
              </span>

              <!-- Column: Default -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <b-form-select
                    v-model="pageLength"
                    hidden
                    class="w-auto mr-1"
                    :options="options"
                    @input="
                      props.perPageChanged({ currentPerPage: pageLength })
                    "
                  />
                  <span class="text-nowrap ">
                    Showing
                    {{
                      pageLength <= props.total
                        ? pageLength * currentPage
                        : props.total
                    }}
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => {
                        currentPage = value
                        props.pageChanged({ currentPage: value })
                      }
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>

      <b-col>
        <b-card class="card" title="Detail Tugas Siswa">
          <div class="d-flex align-items-center mb-1">
            <b-avatar
              :src="studentTaskData.student.avatar"
              :text="avatarText(studentTaskData.student.name)"
            />
            <span class="small-gap-left">
              {{ studentTaskData.student.name }}
            </span>
          </div>

          <span>Hasil Pengerjaan Siswa</span>
          <!-- Submission By Student -->
          <section v-if="studentTaskData.task.type === 'submission'">
            <!-- File Submission -->
            <div
              v-if="studentTaskData.submission_question.type === 'file'"
              class="d-flex my-1"
            >
              <iframe
                class="w-100 submission-preview"
                :src="studentTaskData.answer"
                frameborder="0"
              />
            </div>

            <!-- URL Submission -->
            <div
              v-if="studentTaskData.submission_question.type === 'url'"
              class="d-flex my-1"
            >
              <b-button
                class="float-right"
                variant="primary"
                @click="redirectTo(studentTaskData.answer)"
              >
                <i class="font-medium-2 ti ti-link" />
                Buka Link
              </b-button>
            </div>

            <!-- Text Submission -->
            <div
              v-if="studentTaskData.submission_question.type === 'text'"
              class="d-flex my-1"
            >
              <quill-editor
                v-model="studentTaskData.answer"
                :options="editorOption"
                :disabled="true"
              />
            </div>
          </section>

          <!-- Quiz By Student -->
          <section v-if="studentTaskData.task.type === 'exam'">
            <div
              v-if="
                studentTaskData.examData.quiz_overview.quiz_type === 'multiple'
              "
            >
              <div
                v-for="(quiz, index) in studentTaskData.examData.quiz_overview
                  .quiz_question"
                :key="quiz.id"
                class="question_wrapper my-1"
              >
                <div class="question font-weight-bolder mb-1">
                  {{ index + 1 }}. {{ quiz.question }}
                </div>
                <div
                  v-for="option in quiz.exam_question_options"
                  :key="option.id"
                  class="option-wrapper"
                >
                  <b-form-group class="w-100 small-gap-right">
                    <b-input-group class="small-gap-bottom">
                      <b-input-group-prepend is-text>
                        <b-form-radio
                          v-model="quiz.answer"
                          :value="option.id"
                          plain
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="option.option"
                        @click="quiz.answer = option.id"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div
              v-if="
                studentTaskData.examData.quiz_overview.quiz_type === 'essay'
              "
            >
              <div
                v-for="(quiz, index) in studentTaskData.examData.quiz_overview
                  .quiz_question"
                :key="quiz.id"
                class="question_wrapper"
              >
                <div class="question font-weight-bolder">
                  {{ index + 1 }}. {{ quiz.question }}
                </div>
                <div class="essay-wrapper">
                  <quill-editor
                    v-model="quiz.answer"
                    :disabled="true"
                    class="my-1"
                  />
                </div>
              </div>
            </div>
          </section>

          <b-form-group label="Nilai (0 - 100)">
            <b-form-input v-model="formData.score" type="number" required />
          </b-form-group>
          <b-form-group
            v-if="studentTaskData.task.type === 'submission'"
            label="Tanggapan Guru"
          >
            <b-form-textarea
              v-model="formData.feedback"
              required
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-card>
        <b-button class="float-right" variant="primary" @click="submitGrading">
          <i v-if="!isButtonLoading" class="font-medium-2 ti ti-circle-plus" />
          <b-spinner v-if="isButtonLoading" small variant="light" />
          Simpan Nilai
        </b-button>
      </b-col>
    </b-row>

    <!-- Loading State -->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Tugas Siswa
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// quill
import { quillEditor } from 'vue-quill-editor'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'

// eslint-disable-next-line import/no-cycle
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { pascalCase, avatarText } from '@core/utils/filter'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BFormTextarea,
    BSpinner,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,

    VueGoodTable,
    quillEditor,
  },
  data() {
    return {
      // state
      isLoading: true,
      isButtonLoading: false,

      // quill editor
      editorOption: {
        modules: {
          toolbar: false,
        },
      },

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      studentId: this.$router.currentRoute.params.studentId,

      // Data
      listOfStudents: [],
      studentTaskData: {
        student: {},
        submission_question: {},
        answer: '',
        task: {},
      },

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 5,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Nama Siswa',
          field: 'fullName',
        },
        {
          label: 'Nilai',
          field: 'grade',
          width: '15%',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '5%',
        },
      ],
      rows: [],

      // form data
      formErrors: {
        taskTitle: { state: null, message: '' },
      },
      formData: {
        score: 0,
        feedback: '',
      },
    }
  },
  async mounted() {
    // list student in classroom
    await client
      .get(`/teachers/classrooms/${this.classroomId}/students`)
      .then((response) => {
        const students = response.data.data

        const listOfStudents = students.map((student) => {
          return {
            id: student.id,
            avatar: student.avatar,
            fullName: pascalCase(student.name),
            grade: 0,
            status: 'unavailable',
            feedback: '-',
          }
        })

        this.listOfStudents = listOfStudents
      })
      .catch((error) => {
        console.error(error)
      })

    // fetch grade data list and combine to student list
    this.fetchGradesList()

    // fetch data submission or quiz answer
    const student = this.studentId
    this.fetchDetailData(student)
  },
  methods: {
    avatarText,
    pascalCase,
    checkAuthorizeRole,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    // get detail student task submission / exam
    async fetchDetailData(studentId) {
      this.studentId = studentId
      await client
        .get(
          `teachers/classrooms/${this.classroomId}/grades/show?task_id=${this.taskId}&class_content_id=${this.contentId}&student_id=${studentId}`
        )
        .then((response) => {
          const studentTaskData = response.data.data

          // set form data if already scored
          this.formData.score = studentTaskData.score
          this.formData.feedback = studentTaskData.feedback

          this.studentTaskData = studentTaskData

          if (studentTaskData.task.type === 'exam') {
            this.studentTaskData.examData = JSON.parse(studentTaskData.data)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    // get grade data
    async fetchGradesList() {
      await client
        .get(
          `teachers/classrooms/${this.classroomId}/grades?task_id=${this.taskId}&class_content_id=${this.contentId}`
        )
        .then((response) => {
          const grades = response.data.data

          if (grades.length > 0) {
            this.listOfStudents.map((student) => {
              this.isLoading = false
              const studentGrade = grades.find(
                (grade) => grade.student_id === student.id
              )

              if (studentGrade) {
                if (studentGrade.score == 0) {
                  student.grade = studentGrade.score
                  student.status = 'ungraded'
                } else {
                  student.grade = studentGrade.score
                  student.status = 'success'

                  // set form data if already scored
                  this.formData.score = studentGrade.score
                  this.formData.feedback = studentGrade.feedback
                }
              } else {
                student.grade = 0
                student.status = 'unavailable'
                student.feedback = '-'
              }
            })
          } else {
            this.listOfStudents.map((student) => {
              this.isLoading = false
              student.grade = 0
              student.status = 'unavailable'
              student.feedback = '-'
            })
          }

          const studentsGrades = this.listOfStudents
          this.rows = studentsGrades
        })
        .catch((error) => {
          this.isLoading = false

          console.error(error)
        })
    },

    async submitGrading() {
      this.isButtonLoading = true

      const data = new FormData()
      data.append('score', this.formData.score)
      data.append('feedback', this.formData.feedback)

      await client
        .post(
          `/teachers/classrooms/${this.classroomId}/grades?task_id=${this.taskId}&class_content_id=${this.contentId}&student_id=${this.studentId}`,
          data
        )
        .then((response) => {
          this.isButtonLoading = false
          this.showToast(
            'success',
            'CheckIcon',
            'Success',
            'Berhasil memberi nilai'
          )
          this.fetchGradesList()
        })
        .catch((error) => {
          this.isButtonLoading = false
          this.showToast('danger', 'XIcon', 'Error', 'Gagal memberi nilai')
          console.error(error)
        })
    },

    // redirect
    redirectTo(link) {
      window.open(link, '_blank')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.submission-preview {
  height: calc(70vh - 300px);
}

.essay-wrapper {
  .ql-toolbar {
    display: none;
  }
}
</style>
